import React from "react";
import * as classes from "../../styles/HowItWorkSection.module.scss";
import InfoCard from "./InfoCard";
import imgsrc from "../../images/whywe.svg";
import section2img from "../../images/hiw_section2.svg";
export default function HowItWorkSection() {

  return (
    <>
      <InfoCard
        title="Empowering Employers: Our Unique Edge"
        infoText={
          <>
            <strong>Tailored Expertise:</strong> Our platform’s expert
            recruiters specialize in various industries, offering tailored
            recommendations that align with your company’s vision and goals.
            <br />
            <strong>Monetise Your Vacancies:</strong> Maximize your recruitment
            efficiency by utilizing our cost-effective platform, which ensures
            you only pay for successful hires
            <br />
            <strong>Cutting-Edge Technology:</strong> Experience seamless
            recruitment processes on our state-of-the-art cloud platform.
            <br />
          </>
        }
        img={section2img}
      />

      <InfoCard
        title="How it works"
        description="Dive into our streamlined process where experts, like you, can transform professional connections into successful recommendations. Here's a glimpse of how it unfolds:"
        img={imgsrc}
        infoTitle="Employers"
        infoText={
          <>
            At Experts Circle, we offer three game-changing paths to streamline
            hiring:
            <ul className={classes.stepList}>
              <li>
                <strong>Expert-Matched Recruitment:</strong> Our experts
                actively scout for openings and recommend candidates from their
                professional network, ensuring a match that’s based on deep
                industry insight.
              </li>
              <li>
                <strong>Direct Job Postings:</strong> Post your vacancies
                directly and let our algorithm alert the most suitable expert
                recruiters, who will then recommend top candidates.
              </li>
              <li>
                <strong>Efficient Candidate Review:</strong> Easily review
                expert-vetted profiles, shortlist candidates, and manage the
                hiring process through our intuitive dashboard.
              </li>
            </ul>
            Dive deeper into our revolutionary approach with our{" "}
            <a href="/how_it_works">detailed how it works page</a>.
          </>
        }
      />
    </>
  );
}
